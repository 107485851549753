@media only screen and (min-width: 1700px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 991px) {
  .quotes_div
  {
    .orderHeaderFlex{
      justify-content: center;
      .orderHeader {
        h2{
          font-size: 0.7em;
          margin-bottom: 0.8em;
          flex-grow: 1;
          justify-content: center;
        }
      }
      .fiphone
      {
        margin-top: 0.6em;
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .bg-colorsidebar {
    font-size: 0.875em;
    .logo,
    .userInfo {
      padding: 0.875em;
    }
    .list-group {
      padding: 0 0.875em;
      .nav-link {
        padding: 1em 1em;
      }
    }
  }

  .account_box {
    margin-bottom: 1em;
    .userInfo
    {
      .logo{
        margin-bottom: 1em;
      }
      flex-direction: column;
      .uspad {
        margin-right: 0;
        padding-left: 0; 
        text-align: center;
      }
      .langDiv
      { 
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
      }
    }
   
  }

  .accountBox
  {
    margin-bottom: 1em;
    .account_body
    {
      flex-direction: column;
      align-items: flex-start;
      .account_mleft{
        padding-left: 0;
        padding-top: 0.7em;
      }
    }
  }

  .menuBtn
  {
    min-width: 40px;
    min-height: 40px;
  }

  #page-content-wrapper
  {
    margin-left: 0;
  }

  .topheader
  {
    width: 100%;
  }

  body.sb-sidenav-toggled  #page-content-wrapper
  {
    margin-left: 15em !important;
  }
  #sidebar-wrapper
  {
    margin-left: -25em;
  }
  body.sb-sidenav-toggled #sidebar-wrapper {
    margin-left: 0;
    width: 18em;
  }

  .container-fluid {
    padding-left: 1.3em;
    padding-right: 1.3em;
  }

  .topheader {
    .topheader_flex {
      .inPutBox {
        flex-basis: auto;
      }
    }
  }

  .sidebar
  {
    width: 16em;
  }


}

@media only screen and (max-width: 565px) {
}
