$primary-color: #f7ce6c;
$secondary-color: #1d244a;
$light-color: #d8def5;
$heading-color: #7f8dc8;
$white-color: #ffffff;
$black-color: #000000;
$transparent-color: transparent;
$body-bg: #e9efff;
$header-border: #c8cbd9;
$head-color: #4f5b92;
$border-color: #b3bfec;
$red-color: #e10404;
$light_red: #eb5757;
$fill-color: #f7ce6c;
$title-color: #adabc3;
$icon-color: #767494;
