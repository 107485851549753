@import "./Variables.scss";

.btn-primary {
  font-size: 1em;
  border-radius: 100px;
  padding: 0.6em 1.1em;
  font-weight: 500;
  &.btnLight_Header {
    background-color: $light-color;
    border-color: $light-color;
    color: $secondary-color;
    transition: all ease 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  &.btnPrimary_Header {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $secondary-color;
    transition: all ease 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.btn-primaryAll {
  font-size: 0.875em;
  border-radius: 100px;
  padding: 1em 1.7em;
  font-weight: 500;
  &.btnLight_outline {
    background-color: transparent;
    border-color: $secondary-color;
    color: $secondary-color;
    transition: all ease 0.3s;
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white-color;
    }
  }

  &.btnPrimary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $secondary-color;
    transition: all ease 0.3s;
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white-color;
    }
  }

  &.btnPrimaryoutline {
    background-color: $white-color;
    border-color: $secondary-color;
    color: $secondary-color;
    transition: all ease 0.3s;
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white-color;
    }
  }

  svg{
    margin-right: 0.2em;
  }
}

.contact_btn {
  font-size: 1em;
  border-radius: 50px;
  padding: 20px 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $primary-color;
  transition: all ease 0.3s;
  min-width: 190px;
  &:hover {
    background-color: $transparent-color;
    border-color: $secondary-color;
    color: $secondary-color;
  }
}