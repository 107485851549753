@media only screen and (min-width: 1700px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 1280px) {
  .navbar-toggler {
    border-color: $white-color;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>");
    }
  }

  .navbar-expand-lg {
    .navbar-brand {
      img {
        width: 100%;
      }
    }
    .navbar-nav {
      &.newNav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      .nav-link {
        color: $white-color;
        padding: 1em 0.6em;
        font-size: 1em;
        &.active {
          color: $primary-color;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
    .statscounter_Div 
    {
      .statscounter_main 
      {
        h4 {
          font-size: 3em;
      }  
    }  
  }  
}

@media only screen and (max-width: 991px) {
  .bannerSection {
    &::before {
      clip-path: polygon(0 0, 65% 0, 46% 100%, 0% 100%);
    }
  }
  .statscounter_Div 
  {
    .statscounter_main 
    {
      margin-bottom: 25px;
    }  
  } 
  .transportmain_div
  { 
    .transport_box 
    {
      img 
      {
        height: 240px;
      }
      h4 
      {
        font-size: 1.4em;
      }       
    } 
  } 
  .howitworks_div 
  {
    .howitworks_img 
    {
      img 
      {
        height: 420px;
      }
    }
  }
  .homegetstarted_div 
  {
    padding: 70px 0px;
    .homegetstarted_content 
    {
      padding-left: 0px;
      h4 
      {
        font-size: 2em;
      }      
    }
    .homegetstarted_img 
    {
      img 
      {
        height: 340px;
      }
    }
  }
  .solutionsmain_div 
  {
    .solution_content 
    {
      .solution_head 
      {
        font-size: 3em;  
      }
    }
  }  
  .loginmain_div {
    img.loginimg {
      min-height: auto;
      height: 430px;
    }  
  }
  .trackorder_div 
  {
    .trackorder_search 
    {
      .trackorder_head {
        font-size: 3em;
      }
      .resultlist_div 
      {
        margin-bottom: 30px;
      } 
    } 
  } 
}
@media only screen and (max-width: 767px) {
  .navbar-expand-lg {
    .navbar-brand {
      img {
        width: 9em;
      }
    }
  }
  .transportmain_div
  { 
    .transport_box 
    {
      margin-bottom: 25px;       
    } 
  } 
  .howitworks_div 
  {
    .howitworks_content 
    {
      margin-bottom: 35px;
    }
  } 
  .homegetstarted_div 
  {
    padding: 50px 0px;
    .homegetstarted_img 
    {
      margin-bottom: 25px;
    }
  } 
  .howitworks_div 
  {
    .howitworks_img 
    {
      img 
      {
        height: 360px;
        max-width: 460px;
      }
    }
  } 
  .solutionsmain_div 
  {
    padding: 50px 0px;
    .mb_80 {
      margin-bottom: 50px;
    }
    .solution_content 
    {
      .solution_head 
      {
        font-size: 2em;  
      }
    }
  } 
  .contactmain_div {
    .formtoday_div {
      padding: 20px 30px;
      .contact_head {
        font-size: 3em;
      }
    }
    .contactinfo_div {
      padding: 20px;
      .contact_head {
        font-size: 2em;
      }
    }
    .contactbg_img {
      width: 70%;
    }
  }
  .trackorder_div 
  {
    &.px120 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .trackorder_search 
    {
      .trackorder_head {
        font-size: 2.5em;
      }
    } 
  }             
}

@media only screen and (max-width: 575px) {
  .trackorder_div 
  {
    .showdistance_div  
    {
      .address_distance {
        h5 {
          font-size: 0.7em;
        }
        .distance_show {
          p {
            font-size: 0.7em;
          }
          .distance_line {
            margin: 0px 15px;
          }
        }
      }
      .IDdate_distance {
        gap: 2px;
        h5 {
          font-size: 0.7em;
        }
      }
    } 
  } 
  .tracksearch_field {
    .login_btn {
      font-size: 0px;
      min-width: 68px;
      margin-left: 7px!important;
      .search_icon {
        font-size: 24px;
      }
  }
  }  
}
